<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text capitalize">
              {{ $t('user.edit.title', {fullname: userData.fullname }) }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon> mdi-account-switch</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-form ref="userForm" lazy-validation>
            <v-text-field
              v-model="userData.name"
              :counter="15"
              :rules="validation.nameRules"
              :label="$t('user.edit.name')"
              required
            ></v-text-field>

            <v-text-field
              v-model="userData.lastname"
              :counter="15"
              :rules="validation.lastnameRules"
              :label="$t('user.edit.lastname')"
              required
            ></v-text-field>

            <v-text-field
              v-model="userData.email"
              :rules="validation.emailRules"
              :label="$t('user.edit.email')"
              required
            ></v-text-field>

            <v-text-field
              v-model="userData.password"
              :rules="validation.passwordRules"
              :label="$t('user.edit.password')"
              required
            ></v-text-field>

            <v-select
              v-model="userData.type"
              :items="userTypes"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('user.edit.type.label')"
              :hint="$t('user.edit.type.hint')"
              persistent-hint
              item-value="value"
              item-text="label"
            ></v-select>

            <v-select
              style="margin-top: 10px"
              v-model="userData.vendor_id"
              :items="vendors"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('user.edit.vendor.label')"
              :hint="$t('user.edit.vendor.hint')"
              persistent-hint
              item-value="id"
              item-text="name"
            ></v-select>

            <v-btn
             style="margin-top: 10px"
              color="success"
              class="mr-4 align-self-lg-end"
              @click="updateUser()"
            >
            {{ $t('user.edit.controlls.update') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "EditUsers",
  data() {
    return {
      error: null,
      isLoading: true,
      validation: {
        nameRules: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 10) || "Name must be less than 15 characters",
        ],
        lastnameRules: [
          (v) => !!v || "Lastname is required",
          (v) =>
            (v && v.length <= 10) || "Lastname must be less than 15 characters",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length > 5) || "Passwrod must be more than 5 characther",
        ],
      },
      userData: {
        name: "",
        lastname: "",
        email: "",
        password: "",
        type: "",
        vendor_id: null,
      },
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    userTypes() {
      if(this.applicationData) {
        return this.applicationData.user_types
      }
      return []
    },
    vendors() {
      if(this.applicationData) {
        return this.applicationData.vendors
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch("loadApplicationData")
    this.getUser()
  },
  methods: {
    getUser() {
      this.$axios({
        method: "get",
        url: this.$globals.api.user.get + this.$route.params.id,
      })
        .then((response) => {
          this.userData.name = response.data.data.name;
          this.userData.lastname = response.data.data.lastname;
          this.userData.email = response.data.data.email;
          this.userData.type = response.data.data.type;
          this.userData.vendor_id = response.data.data.vendor_id;
          this.userData.fullname = response.data.data.fullname;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    updateUser() {
      this.$axios({
        method: "post",
        url: this.$globals.api.user.update + this.$route.params.id,
        data: this.userData,
      })
        .then(() => {
          this.$router.push({ name: "user/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>